exports.onRouteUpdateDelayed = ({ location, prevLocation }) => {
  window.document.body.style.setProperty("cursor", "wait", "important");
  window.document.body.style.setProperty("filter", "blur(15px)", "important");
};

exports.onRouteUpdate = ({ location, prevLocation }) => {
  window.document.body.style.removeProperty("cursor");
  window.document.body.style.removeProperty("filter");
  if(prevLocation != null) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ isEntry: false });
  }
  else{
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ isEntry: true });
  }
};

if (process.env.NODE_ENV === 'development') {
  if (!window.__MOCKS_INITIALIZED__) {
    console.log('Initializing mocks for development environment...');

    window.googletag = {
      cmd: [],
      pubads: () => ({
        getSlots: () => [
          {
            getSlotElementId: () => 'div-gpt-ad-1407836115662-0',
            getAdUnitPath: () => 'mock-ad-unit-path',
          },
        ],
        refresh: () => console.log('Mock refresh called'),
        disableInitialLoad: () => console.log('Mock disableInitialLoad called'),
      }),
      defineSlot: () => ({
        addService: () => ({}),
      }),
      display: (slotId) => console.log(`Mock display called for ${slotId}`),
    };

    window.yieldlove_cmd = window.yieldlove_cmd || [];
    window.yieldlove_cmd.push = (callback) => {
      console.log('Mock yieldlove_cmd callback executed:', callback);
      console.log('YLHH at callback execution:', window.YLHH);
      if (typeof callback === 'function') {
        try {
          callback();
        } catch (error) {
          console.error('Error executing callback in yieldlove_cmd.push:', error);
        }
      } else {
        console.error('Callback is not a function:', callback);
      }
    };

    window.YLHH = {
      init: () => console.log('Mock YLHH init called'),
      startAuction: () => console.log('Mock YLHH startAuction called'),
    };

    if (window.YLHH && typeof window.YLHH.startAuction === 'function') {
      window.YLHH.startAuction();
    } else {
      console.warn('YLHH.startAuction is not available. Skipping...');
    }

    console.log('Mocks initialized:', window.YLHH);

    setInterval(() => {
      console.log('YLHH state:', window.YLHH);
    }, 5000);

    console.log('YLHH after external script load:', window.YLHH);

    // Set the flag to indicate mocks are initialized
    window.__MOCKS_INITIALIZED__ = true;
  } else {
    console.log('Mocks already initialized. Skipping reinitialization.');
  }
}
